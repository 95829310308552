<script setup>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import AuthenticationCardAvatar from '@/Components/AuthenticationCardAvatar.vue';
import AuthenticationCardButton from '@/Components/AuthenticationCardButton.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';

defineProps({
	canResetPassword: Boolean,
	canRegister: Boolean,
	status: String,
});

const form = useForm({
	email: '',
	password: '',
	remember: false,
});

const submit = () => {
	form.transform((data) => ({
		...data,
		remember: form.remember ? 'on' : '',
	})).post(route('login'), {
		onFinish: () => form.reset('password'),
	});
};
</script>

<template>
	<Head title="Log in" />

	<AuthenticationCard>
		<template #avatar>
			<AuthenticationCardAvatar avatar="fa solid fa-user-lock pl-1" />
		</template>

		<template #logo>
			<AuthenticationCardLogo />
		</template>

		<template #header>
			<h4 class="block mb-0 font-bold text-white spectrio-header-font">Sign In</h4>
			<p class="block mb-0 text-sm text-white/70">Enter your email and password</p>
		</template>

		<template #status>{{ status }}</template>

		<div class="flex-auto p-6">
			<form @submit.prevent="submit">
				<div>
					<InputLabel for="email"
						><i class="fa-solid fa-envelope"></i>
						&nbsp;Email
					</InputLabel>
					<TextInput
						id="email"
						v-model="form.email"
						type="email"
						class="block w-full mt-1"
						required
						autocomplete="email"
						autofocus />
					<InputError class="mt-2" :message="form.errors.email" />
				</div>

				<div class="mt-4">
					<InputLabel for="password"> <i class="fa-solid fa-key"></i>&nbsp;Password </InputLabel>
					<TextInput
						id="password"
						v-model="form.password"
						type="password"
						class="block w-full mt-1"
						required
						autocomplete="current-password" />
					<InputError class="mt-2" :message="form.errors.password" />
				</div>

				<div class="flex flex-row items-center justify-center mt-4">
					<label class="items-center flex-1">
						<Checkbox v-model:checked="form.remember" name="remember" />
						<span class="ml-2 text-sm text-gray-600 align-middle">Remember me</span>
					</label>
					<AuthenticationCardButton
						class="items-center justify-center flex-1 w-2/5 ml-4"
						:class="{ 'opacity-25': form.processing }"
						:disabled="form.processing">
						Log in
					</AuthenticationCardButton>
				</div>
			</form>
		</div>
		<div
			v-if="canResetPassword"
			class="border-black/12.5 rounded-b-2xl border-t-0 border-solid p-6 pb-2 text-center pt-0 px-1 sm:px-6">
			<p class="mx-auto mb-2 text-sm font-normal">
				Trouble signing in?
				<Link :href="route('password.request')" class="font-semibold text-primary"> Reset Password</Link>
			</p>
		</div>
		<template #footer>
			<!-- <div v-if="canRegister" class="p-6 px-1 py-3 text-center dash-auth-footer sm:px-6 rounded-b-2xl">
				<p class="mx-auto mb-0 text-sm font-normal">
					Don't have an account?
					<Link :href="route('register')" class="font-semibold text-primary"> Register</Link>
				</p>
			</div> -->
		</template>
	</AuthenticationCard>
</template>
